<template>
  <div class="overflow-hidden">
    <SiteNav />
    <div class="">
      <section class="relative">
        <div
          class="hero bg-landingGrey relative pt-12 lg:pt-6 lg:pb-0 mb-24 overflow-hidden"
        >
          <div class="l-container">
            <div
              class="pb-14 md:pb-0 text-center flex flex-col-reverse lg:text-left lg:grid grid-cols-2 gap-8 items-center"
            >
              <div class="">
                <p class="tagline">Assessment</p>
                <h1 class="heading level-2 mb-4">
                  Recruitment <br class="hidden md:block" />
                  Made Easy
                </h1>
                <p class="text-summary text-lg md:w-4/5 mx-auto lg:mx-0 mb-14">
                  Use our world-class online exam management tool with
                  AI-enabled proctoring tool for all your recruitment needs and
                  career interests or job role tests.
                </p>

                <a
                  class="bg-primary py-5 px-11 rounded-full text-white shadow-lg"
                  target="_blank"
                  href="https://tally.so/r/mO5RKk"
                >
                  Get started
                </a>
              </div>
              <div class="max-w-2xl mx-auto relative">
                <div style="z-index: 1" class="absolute -top-20 -left-20">
                  <img src="./assets/blur.svg" />
                </div>
                <img src="./assets/img-3.png" class="relative z-10" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="hidden z-10 md:flex w-full flex-col absolute -bottom-0 md:-bottom-28"
        >
          <img class="w-full h-14 md:h-20" src="./assets/swiggleSingle.png" />
          <img class="w-full h-14 md:h-20" src="./assets/swiggleSingle.png" />
        </div>
        <div
          class="flex md:hidden w-full flex-col absolute -bottom-20 md:-bottom-28"
        >
          <img class="w-full h-28" src="./assets/mobileWiggle.png" />
        </div>
      </section>

      <section class="mt-12 md:mt-32">
        <div class="flex flex-col sm:gap-2 mx-3 md:mx-32">
          <span class="text-base text-primary font-semibold"
            >Our Assessment Resources</span
          >
          <p class="">Get access to world class questions with full reports.</p>

          <div class="hidden md:block">
            <a
              href="https://tally.so/r/mO5RKk"
              target="_blank"
              class="text-sm focus:outline-none font-medium fo text-primary flex items-center gap-2"
            >
              Explore now
              <i class="pi pi-arrow-right text-xs"></i>
            </a>
          </div>

          <div
            :class="{
              'flex items-center overflow-x-hidden': true,
              relative: isInfinite,
            }"
          ></div>

          <div class="mt-8">
            <!-- <div class="hidden md:flex items-center gap-4 justify-around">
            <IconCard
              v-for="(item, i) in infoList"
              :key="`info-${i}`"
              :title="item.title"
              :color="item.color"
            />
          </div> -->

            <InfinitScrollVue>
              <IconCard
                :assesment="true"
                v-for="(item, i) in infoList"
                :key="`info-${i}`"
                :title="item.title"
                :color="item.color"
              />
            </InfinitScrollVue>
          </div>
        </div>
      </section>
      <section class="my-12 md:my-32">
        <div class="l-container">
          <p class="text-center font-bold text-2xl md:text-4xl mb-12">
            Why Our Assessments?
          </p>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-11/12 md:w-full mx-auto gap-6 mt-6"
          >
            <DeviceCard
              :IconSrc="vaultIcon"
              title="Top-tier Security"
              description="One of the most secure tool in the world."
            />
            <DeviceCard
              :IconSrc="cancelIcon"
              title="Anti-Cheating Service"
              description="Designed to prevent cheating all-round."
            />
            <DeviceCard
              :IconSrc="cloudIcon"
              title="Auto-save Test"
              description="Configured against power failure."
            /><DeviceCard
              :IconSrc="capIcon"
              title="Research-Backed"
              description="Solidly backed by research."
            />
          </div>
          <img src="./assets/wiggle.svg" class="hidden md:block my-10" />
        </div>
      </section>
      <div class="md:hidden mb-20 flex justify-center">
        <a
          href="https://tally.so/r/mO5RKk"
          target="_blank"
          class="text-base focus:outline-none font-medium text-primary flex items-center gap-2"
        >
          Explore now
          <i class="pi pi-arrow-right text-xs"></i>
        </a>
      </div>
      <section
        :style="{
          backgroundImage: ' url(' + secondmobileBackground + ')',
        }"
        style="
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        "
        class="bg-primary mb-20 bg-opacity-100 rounded-4xl hidden md:flex py-16 flex-col justify-center items-center gap-9 w-10/12 mx-auto"
      >
        <p class="text-white font-bold text-center text-3xl">
          Choose our career assessment tool now for accurate insights, <br />
          ensuring you quickly hire the right candidate for each role, <br />
          without the complexity of multiple assessments.
        </p>
        <a
          class="bg-mint p-3 rounded-full text-primary text-sm"
          shadow
          href="https://tally.so/r/mO5RKk"
          target="_blank"
        >
          Get started
        </a>
      </section>
      <section
        :style="{
          backgroundImage: ' url(' + secondMobile + ')',
        }"
        style="
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        "
        class="bg-primary text-center mb-12 bg-opacity-100 rounded-4xl md:hidden flex py-10 flex-col justify-center items-center gap-9 w-11/12 mx-auto"
      >
        <p class="text-white font-bold text-xl">
          Choose our career assessment <br />
          tool now for accurate insights, <br />
          ensuring you quickly hire the right <br />
          candidate for each role, without <br />
          the complexity of multiple <br />
          assessments.
        </p>
        <a
          class="bg-mint text-primary text-sm p-3 rounded-full"
          shadow
          href="https://tally.so/r/mO5RKk"
          target="_blank"
        >
          Get started
        </a>
      </section>
    </div>
    <SiteFooter />
  </div>
</template>

<script setup>
import { ref } from "vue";
import SiteFooter from "../../components/SiteFooter.vue";
import SiteNav from "../../components/SiteNav.vue";
import SiteButton from "../../components/SiteButton.vue";
import DeviceCard from "../../components/DeviceCard.vue";
import secondmobileBackground from "./assets/secondmobileBackground.png";
import secondMobile from "./assets/secondMobile.png";
import InfinitScrollVue from "../../components/InfinitScroll.vue";
import IconCard from "../../components/IconCard.vue";

import vaultIcon from "./assets/vault.svg";
import cancelIcon from "./assets/cancel.svg";
import cloudIcon from "./assets/cloud.svg";
import capIcon from "./assets/cap.svg";

const infoList = [
  {
    title: "Psychometric Test",
    color: "secondary",
  },
  {
    title: "Case Study Test",
    color: "primary",
  },
  {
    title: `Front-End <br> Engineering Test`,
    color: "secondary",
  },
  {
    title: "Civil Service test",
    color: "primary",
  },
  {
    title: "Full-Stack Test",
    color: "secondary",
  },
  {
    title: "Back-End Engineering Test",
    color: "primary",
  },
  {
    title: "Maths & <br> Numeracy Test (Levels 1 - 3)",
    color: "secondary",
  },
  {
    title: "Electrical <br> Comprehension Test",
    color: "primary",
  },
];
const tabs = ref([
  "Onboarding",
  "Offboarding",
  "Control app access & device usage",
]);
const activeTab = ref("Onboarding");
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #292a29;
  }
}

.freedom-section {
  background: #f8f8f8;
  .heading {
    font-family: "Roboto";
    span {
      color: #ea4e4b;
    }
  }
}

.what-we-offer-section {
  .heading {
    font-family: "Roboto";
  }
}
</style>
